import React from 'react'
import PropTypes from 'prop-types'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import {useMixpanel} from "gatsby-plugin-mixpanel";

import {
  CtaTrialSection,
  CtaTrialWrapper,
  ColumnLeft,
  ColumnRight,
  Title,
  Subtitle,
  ImageContainer,
  ImageContainerText
} from './styles'

import Container from '../../UI/Container'
import Button from '../../Button'

const CtaTrial = ({ data, location }) => {
  const mixpanel = useMixpanel()
  const {
    title,
    description,
    button_text,
    button_link,
    image_text,
    image: {
      localFile: {
        childImageSharp: {
          fluid: { src }
        }
      }
    }
  } = data
  return (
    <CtaTrialSection>
      <Container addMargin width={1600}>
        <CtaTrialWrapper>
          <ColumnLeft>
            <Title>{title}</Title>
            <Subtitle dangerouslySetInnerHTML={{ __html: description }} />
            <Button
              type="large"
              href={`${button_link}${location.search}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => handleTrackedRentlioWebappClick(e, button_link, location.search, mixpanel, 'try_free_button_clicked', 'cta_box_bottom', true, true, true)}
            >
              {button_text}
            </Button>
          </ColumnLeft>
          <ColumnRight>
            <ImageContainer imageUrl={src}>
              <ImageContainerText>{image_text}</ImageContainerText>
            </ImageContainer>
          </ColumnRight>
        </CtaTrialWrapper>
      </Container>
    </CtaTrialSection>
  )
}

CtaTrial.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    button_text: PropTypes.string,
    image_text: PropTypes.string,
    image: PropTypes.object
  })
}

CtaTrial.defaultProps = {
  location: {
    search: ''
  }
}

export default CtaTrial
