import React, { useState } from 'react'

import { FAQContentSection, FAQCategoriesFilter, Row, Column } from './styles'
import Container from '../../UI/Container'
import QuestionsList from './List'

const FAQContent = ({ data, locale }) => {
  const [activeCategory, setActiveCategory] = useState(locale === 'hr' ? 'Sve' : 'All')

  const questionCategories = data
    .map(category => category.node.faq_category[0].name)
    .filter((q, i, array) => array.indexOf(q) === i)

  questionCategories.unshift(locale === 'hr' ? 'Sve' : 'All')

  const CategoryList = ({ categories }) => {
    return categories.map((cat, i) => (
      <li
        onClick={() => setActiveCategory(cat)}
        key={`${cat}-${i}`}
        className={activeCategory === cat ? 'active' : null}
      >
        {cat}
      </li>
    ))
  }

  return (
    <FAQContentSection>
      <Container>
        <Row>
          <Column width="25">
            <FAQCategoriesFilter>
              <CategoryList categories={questionCategories} />
            </FAQCategoriesFilter>
          </Column>
          <Column width="75">
            <QuestionsList questions={data} activeCategory={activeCategory} />
          </Column>
        </Row>
      </Container>
    </FAQContentSection>
  )
}

export default FAQContent
