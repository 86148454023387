import React from 'react'

import { CategoryName, ContentBox } from './styles'

const QuestionsList = ({ questions, activeCategory }) => {
  let categoryNames = {}

  const QuestionItem = ({ title, content }) => {
    return (
      <ContentBox>
        <h6>{title}</h6>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </ContentBox>
    )
  }

  return (
    <>
      {questions
        .filter(
          question =>
            activeCategory === question.node.faq_category[0].name ||
            activeCategory === 'All' ||
            activeCategory === 'Sve'
        )

        .map(
          ({
            node: {
              faq_category,
              title,
              id,
              acf: { question_content }
            }
          }) => {
            if (!categoryNames[faq_category[0].name]) {
              categoryNames[faq_category[0].name] = faq_category[0].name

              return (
                <div key={id}>
                  <CategoryName>{faq_category[0].name}</CategoryName>
                  <QuestionItem title={title} content={question_content} />
                </div>
              )
            }
            return (
              <div key={id}>
                <QuestionItem title={title} content={question_content} />
              </div>
            )
          }
        )}
    </>
  )
}

export default QuestionsList
