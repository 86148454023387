import styled from 'styled-components'

export const FAQContentSection = styled.section`
  padding: 5rem 0;

  @media only screen and (max-width: 425px) {
    padding: 1rem 0;
  }
`

export const Row = styled.div`
  display: flex;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${({ width }) => width}%;
`

export const FAQCategoriesFilter = styled.ul`
  display: flex;
  flex-direction: column;

  li {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1.14rem;
    line-height: 1.71rem;
    padding: 1rem 0;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.vividBlue};
    }
    &.active {
      color: ${({ theme }) => theme.colors.vividBlue};
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1rem 0 5rem 0;
  }
`
