import styled from 'styled-components'

export const CategoryName = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: 1.71rem;
  text-transform: uppercase;
  margin-bottom: 5.71rem;

  &::before {
    content: ' ';
    left: 0;
    display: inline-flex;
    height: 2px;
    width: 40px;
    background: linear-gradient(
      90deg,
      #a9cf3a 0%,
      #a7cf40 5.15%,
      #a2ce52 12.91%,
      #99cd6f 22.31%,
      #8bcb92 32.97%,
      #78cabb 44.65%,
      #59c9e7 56.32%,
      #1d9ed9 99.98%
    );
    margin-right: 1rem;
    vertical-align: middle;
  }
`
export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 7.71rem;

  h6 {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: 1.71rem;
    font-weight: 800;
    line-height: 2.14rem;
    margin-bottom: 2.14rem;
  }

  a {
    color: ${({ theme }) => theme.colors.vividBlue};
  }

  p {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1.14rem;
    line-height: inherit;
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;
    width: 780px;
    margin: 2rem auto;

    li {
      color: ${({ theme }) => theme.colors.darkGrey};
      font-size: 1.14rem;
      line-height: 2.14rem;
      padding-left: 1.5rem;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    margin-bottom: 4.28rem;

    ul {
      width: 90%;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
`
