import styled from 'styled-components'

export const CtaTrialSection = styled.section`
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  background: radial-gradient(91.53% 537.19% at 34.46% 108.61%, #0d3151 0%, #134776 100%);
`

export const CtaTrialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 510px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
`

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  align-items: flex-start;
  padding: 5rem;

  @media screen and (max-width: 1024px) {
    align-items: center;
    padding: 5rem 2rem;
  }
`

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
  align-items: center;
`

export const Title = styled.h3`
  font-family: 'Barlow';
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 3.28rem;
  font-weight: 800;
  line-height: 3.57rem;
  margin-bottom: 2.5rem;

  @media screen and (max-width: 1024px) {
    text-align: center;
    font-size: 3rem;
    font-weight: 800;
    line-height: 3.2rem;
  }
`

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 1.14rem;
  line-height: 2rem;
  width: 500px;
  margin-bottom: 4rem;

  @media screen and (max-width: 1024px) {
    width: 100%;
    text-align: center;
  }
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(13, 49, 81, 0) 33.14%, rgba(13, 49, 81, 0.7) 100%),
    url(${({ imageUrl }) => imageUrl}) no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 1024px) {
    height: 500px;
    text-align: center;
  }
`
export const ImageContainerText = styled.h4`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-size: 2.14rem;
  font-weight: 800;
  line-height: 2.85rem;
  padding: 4rem;
`
