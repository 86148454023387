import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import FAQIntro from 'components/FAQ-screen/FAQ-intro'
import CtaTrial from 'components/CTA/Trial'
import FAQContent from 'components/FAQ-screen/FAQ-content'

const MainContent = styled.main`
  background-color: #ffffff;
  position: relative;
  z-index: 1;
`

const Integrations = ({ data, pageContext, location }) => {
  const {
    faqs: { edges: faqs },
    faq: {
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      content,
      ctaData: { cta }
    }
  } = data

  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
      />
      <Header mode="dark" locale={pageContext.locale} opacity={0.3} location={location} />
      <FAQIntro data={content} />
      <MainContent>
        <FAQContent data={faqs} locale={pageContext.locale} />
        <CtaTrial data={cta} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default Integrations

export const query = graphql`
  query($id: Int, $locale: String!) {
    faq: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      content: acf {
        title
        subtitle
        image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 580) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      ctaData: acf {
        cta {
          title
          description
          button_text
          button_link
          image_text
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    faqs: allWordpressWpFaq(filter: { lang: { eq: $locale } }, sort: { fields: faq_category___wordpress_id }) {
      edges {
        node {
          id
          title
          acf {
            question_content
          }
          faq_category {
            name
          }
        }
      }
    }
  }
`
