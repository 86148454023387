import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { FAQIntroWrapper, Row, Column, Shadow, Title, Subtitle, ImageContainer } from './styles'
import Container from '../../UI/Container'

const FAQIntro = ({ data }) => {
  const {
    title,
    subtitle,
    image: {
      localFile: {
        childImageSharp: { fluid }
      }
    }
  } = data
  return (
    <FAQIntroWrapper>
      <Container>
        <Shadow />
        <Row>
          <Column align="flex-start">
            <Subtitle>{subtitle}</Subtitle>
            <Title>{title}</Title>
          </Column>
          <Column align="flex-end">
            <ImageContainer>
              <Img fluid={fluid} />
            </ImageContainer>
          </Column>
        </Row>
      </Container>
    </FAQIntroWrapper>
  )
}

FAQIntro.propTypes = {
  data: PropTypes.object
}

export default FAQIntro
