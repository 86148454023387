import styled from 'styled-components'

export const FAQIntroWrapper = styled.div`
  padding: 10rem 0 0;
  background: linear-gradient(261.34deg, #f7f7f7 3.39%, rgba(247, 247, 247, 0) 95.53%);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    bottom: -1px;
    z-index: 1;

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 22%, 0 100%);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      clip-path: polygon(0 100%, 100% 100%, 100% 22%, 0 100%);
    }
  }

  @media only screen and (max-width: 1024px) {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 100%;
      top: -8px;
      height: 100%;
      background-position: center;
      background: radial-gradient(50% 42.5% at 50% 42.5%, rgba(241, 103, 103, 0.19) 0%, rgba(255, 255, 255, 0) 100%),
        radial-gradient(46.32% 39.38% at 49.17% 90.63%, rgba(29, 158, 217, 0.47) 0%, rgba(29, 158, 217, 0) 100%);
      opacity: 0.7;
      transform: translateY(63%);
      background-repeat: no-repeat;
      background-size: 480px 272px;
      pointer-events: none;
      z-index: 0;
    }

    &::before {
      clip-path: polygon(0 100%, 100% 100%, 100% 78%, -45% 100%);
    }
  }
`
export const Shadow = styled.div`
  position: relative;
  display: flex;

  @media only screen and (min-width: 1025px) {
    &::after {
      content: '';
      position: absolute;
      right: 65px;
      width: 480px;
      top: -50px;
      height: 380px;
      background-position: center;
      background: radial-gradient(50% 42.5% at 50% 42.5%, rgba(241, 103, 103, 0.19) 0%, rgba(255, 255, 255, 0) 100%),
        radial-gradient(46.32% 39.38% at 49.17% 90.63%, rgba(29, 158, 217, 0.47) 0%, rgba(29, 158, 217, 0) 100%);
      opacity: 0.7;
      transform: rotate(-19deg) translateY(-21%);
      background-repeat: no-repeat;
      background-size: 480px 272px;
      pointer-events: none;
    }
  }
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 400px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ align }) => align};
  flex-basis: 50%;
  z-index: 1;

  @media screen and (max-width: 425px) {
    align-items: center;
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 4.28rem;
  font-weight: 800;
  line-height: 5.14rem;
  margin: 2rem 0;
  width: 580px;
  z-index: 1;

  @media only screen and (max-width: 1024px) {
    text-align: center;
    width: 90%;
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

export const Subtitle = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1.42rem;
  font-weight: 500;
  line-height: 2.14rem;
  opacity: 0.6;
  text-transform: uppercase;
  margin-bottom: 2rem;
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    display: block;
    height: 2px;
    width: 20px;
    background: linear-gradient(
      90deg,
      #a9cf3a 0%,
      #a7cf40 5.15%,
      #a2ce52 12.91%,
      #99cd6f 22.31%,
      #8bcb92 32.97%,
      #78cabb 44.65%,
      #59c9e7 56.32%,
      #1d9ed9 99.98%
    );
    margin-top: 1rem;
  }

  @media screen and (max-width: 425px) {
    font-size: 1.1rem;
    line-height: 2rem;
    text-align: center;

    &::after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

export const ImageContainer = styled.figure`
  width: 100%;
  height: 100%;
  max-width: 480px;

  @media only screen and (max-width: 1024px) {
    width: 400px;
  }

  @media only screen and (max-width: 768px) {
    width: 310px;
  }
`
